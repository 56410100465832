<template>
  <v-app>
    <notifications
      group="alert"
      position="top center"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div class="vue-notification">
          <a class="notification-title">
            {{props.item.title}}
          </a>
          <a
            class="notification-close"
            @click="props.close"
          >
            <i class="mdi mdi-close-circle"></i>
          </a>
          <div
            v-html="props.item.text"
            class="notification-content"
          >
          </div>
        </div>
      </template>
    </notifications>

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
  import Utils from '@/services/Utils'

  export default {
    name: 'DashboardIndex',
    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },
    beforeMount: function () {
      const user = Utils.getUser()
      if (!window.strings) {
        const cacheStrings = Utils.getStorage('strings')
        if (cacheStrings) {
          window.strings = cacheStrings
        } else {
          window.strings = {}
        }
      }
      if (!user) {
        this.$router.navigate.replace({ path: '/' })
      }
    },
    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
